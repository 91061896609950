'use client';
import Link from 'next/link';
import { setCookie } from 'cookies-next';
import { COOKIE_NAMES, GENDER } from '@/app/constants';
import React from 'react';
import { useTranslation } from 'next-i18next';

const GenderButtons = ({ domain, localeFromPath }: { domain: string; localeFromPath: string }) => {
  const { t } = useTranslation('navigation');
  const domainWithoutSubdomain = '.' + domain.split('.').slice(-2).join('.');

  return (
    <div className={'d-flex gap-2'}>
      {[GENDER.WOMAN, GENDER.MAN].map((gender) => (
        <Link
          className={'btn btn-black-hover-light'}
          key={gender}
          onClick={() =>
            setCookie(COOKIE_NAMES.GENDER, gender, {
              path: '/',
              domain: domainWithoutSubdomain,
              maxAge: 60 * 60 * 24 * 365,
            })
          }
          href={localeFromPath === 'de' ? '/' : `/${localeFromPath}/`}>
          {t(gender.toUpperCase())}
        </Link>
      ))}
    </div>
  );
};

export default GenderButtons;
